<script>
import Sketch from "./components/Sketch.svelte";
import Settings from "./components/Settings.svelte";
</script>


<main>
	<Sketch />
	<Settings />
</main>


<svelte:head>
		<title>N-Queen</title>
</svelte:head>

<style global>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply  box-content h-screen w-screen p-0 m-0 overflow-hidden;
	}
}

@layer components {
	.settings-section{
		@apply font-[Roboto] rounded-md bg-[rgb(237,237,237)] p-2 antialiased  text-slate-800 shadow-sm;
	}
}
</style>
import {writable} from "svelte/store";

function createSettings() {
	const storageKey = "nQueenSettings";
	const defaultState = {
		dimension: 4,
		isSolved: false,
		status: "unSolved",
		iterations: 0,
		board: new p5.Table(),
		boardIterator: null,
	};
	const {subscribe, set, update} = writable(fetchFromStorage());
	//-------------------------------------------------------------//
	function fetchFromStorage() {
		const storageItem = localStorage.getItem(storageKey);
		return storageItem ? JSON.parse(storageItem) : defaultState;
	}

	function updateAndSave(state) {
		update(curr => {
			(state = {...curr, ...state}),
				localStorage.setItem(storageKey, JSON.stringify(state));
			return state;
		});
	}

	const setDimension = dim => {
		updateAndSave({dimension: dim});
	};
	const setSolved = () => {
		updateAndSave({status: "Solved", isSolved: true});
	};
	const setUnsolved = () => {
		updateAndSave({status: "Unsolved", isSolved: false});
	};
	const setIterations = count => {
		updateAndSave({iterations: count});
	};

  const clearSettings = () => {
    updateAndSave({
      status:'Unsolved',
      isSolved:false,
      iterations:0
    })
  }
	return {
    subscribe,
		setDimension,
		setSolved,
		setUnsolved,
		setIterations,
		setSolved,
		clearSettings,
	};
}



//----------------------------------------------------------------------------------//
function createDimension(){
  const storageKey = 'dimension';
  const defaultValue = 4;
  const {subscribe, set, update} = writable(fetchFromStorage());

  function fetchFromStorage(){
    const storedValue = localStorage.getItem(storageKey);
    return storedValue? JSON.parse(storedValue) : defaultValue;
  }

  function updateDimension(dim){
    set(dim);
    localStorage.setItem(storageKey, dim);
  }
  return {
    subscribe,
    set: updateDimension
  }
}
export const dimension = new createDimension();
//-----------------------------------------------------------------------------------//

//----------------------------------------------------------------------------------//
function createRunStatus(){
  const {subscribe, set, update} = writable({status:'UNSOLVED', isSolved:false});
  function updateDimension(isSolved){
    set({status:isSolved?'SOLVED':'UNSOLVED', isSolved});
  }
  return {
    subscribe,
    set: updateDimension
  }
}
export const runStatus = new createRunStatus();
//-----------------------------------------------------------------------------------//


//----------------------------------------------------------------------------------//
function createIterationCount(){
  const {subscribe, set, update} = writable(0);
  function increment(){
		update(val => val+1);
  }
	function reset(){
		set(0);
	}
  return {
    subscribe,
		set:increment,
		reset
  }
}
export const iterationCount = new createIterationCount();
//-----------------------------------------------------------------------------------//

//----------------------------------------------------------------------------------//
function createSolution(){
  const {subscribe, set, update} = writable('');

	function reset(){
		set('');
	}
  return {
    subscribe,
		set,
		reset
  }
}
export const solution = new createSolution();
//-----------------------------------------------------------------------------------//
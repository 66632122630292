<script context="module">
  let qs = window.$;
</script>

<script>
	import {
		dimension,
		runStatus,
		iterationCount,
		solution,
	} from "../stores/settings";
	import {onMount} from "svelte";
	import {board, backtracking} from "./Sketch.svelte";
	onMount(() => {
		let settingsRef = QuickSettings.create(10, 10, "");
		settingsRef.setWidth(220);
		qs(".qs_title_bar").html(qs("#title_bar"));
		qs(".qs_main").append(qs("#controls-body"));
		return settingsRef.destroy;
	});
</script>

<div id="controls">
	<div id="title_bar" class="text-slate-700 text-[0.9rem] text-center">
		N-Queen Algorithms
	</div>
	<div id="controls-body" class="p-1.5 flex flex-col gap-1.5">
		<div
			class="flex justify-between w-full gap-1 children:h-16 children:w-10
      children:bg-white children:rounded-md children:shadow-md !shadow-none settings-section !p-1.5"
		>
			<img src="/black-queen.svg" alt="" />
			<img src="/white-queen.svg" class="!bg-black" alt="" />
			<img src="/blue-queen.svg" alt="" />
			<img src="/red-queen.svg" alt="" />
		</div>
		<div class="settings-section space-y-2">
			<details class="cursor-text">
				<summary class="font-semibold cursor-pointer  !text-slate-700 mb-1"
					>README</summary
				>
				<div class="mb-1">
					The optimized backtracking algorithm is a modified permutation depth
					first search algorithm that selects none attacking cells orderly but
					invalidates them randomly. This reduces the cost of holding on to
					non-viable cells(solutions in a local search space that do not exist
					in the global space) from O(N&sup2;!) to O(N&sup2;).
				</div>
			</details>
		</div>
		<div class="settings-section">
			<p
				class="text-center w-full font-semibold antialiased text-sm  !text-slate-700"
			>
				Keyboard Shortcuts
			</p>
			<p class="text-center text-xs mb-1">*drag Title to Move Panel*</p>
			<div
				class="flex flex-c flex-wrap gap-x-4 gap-y-1 [&>p>:nth-child(2)]:text-sm
        [&>p]:flex [&>p]:gap-1 [&>p]:items-center"
			>
				<p>
					<span class="kbd bg-white">H</span>
					<span>Hide/Reveal</span>
				</p>
				<p>
					<span class="kbd bg-white">M</span>
					<span>Collapse/Expand</span>
				</p>
				<p>
					<span class="kbd bg-white solve">S</span>
					<span>Solve</span>
				</p>
				<p>
					<span class="kbd bg-white clear">C</span>
					<span>Clear</span>
				</p>
			</div>
		</div>

		<div class="settings-section">
			<p class="text-sm">
				<span class="font-semibold antialiased text-slate-600">Dimension:</span>
				<span>{$dimension}</span>
			</p>
			<input
				type="range"
				min="4"
				max="100"
				bind:value={$dimension}
				class="range range-xs border-none"
			/>
		</div>
		<div class="settings-section">
			<select class="select w-full max-w-xs select-xs !bg-white rounded-md">
				<!-- <option disabled selected>Select an Algorithm</option> -->
				<option selected>Optimized Backtracking</option>
			</select>
		</div>

		<div
			class="settings-section flex justify-around children:!text-[rgb(237,237,237)]"
		>
			<button
				class="btn btn-xs !bg-red-700  !border-red-900"
				on:click={() => board.reset()}>Clear</button
			>
			<button
				class="btn btn-xs !bg-green-700 !border-green-900"
				on:click={() => {
					board.reset();
					backtracking.run();
				}}>Solve</button
			>
		</div>

		<div class="settings-section flex justify-around text-center">
			<div class="text-sm font-semibold  !text-slate-700">
				<p>#iterations</p>
				<p>{$iterationCount}</p>
			</div>
			<div class="text-sm font-semibold  !text-slate-700">
				<p>Status</p>
				<p
					id="outcome"
					class={`${$runStatus.isSolved ? "text-blue-800" : "text-red-800"}`}
				>
					{$runStatus.status}
				</p>
			</div>
		</div>

		<div class="settings-section">
			<p class="font-semibold text-sm text-center">Solution</p>
			<p class="text-center mb-1">
				*Solution are numbered cells, starting with the top-left-most cell at
				#0, moving left to right, from top to bottom.*
			</p>
			<div
				class="border border-slate-900 w-full  rounded-sm p-1 text-center overflow-y-auto font-medium"
			>
				{$solution}
			</div>
		</div>
	</div>
</div>

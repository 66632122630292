<script context="module">
	export class Cell {
		constructor(props) {
			this.hasQueen = false;
			this.key = props.key;
			this.dim = props.dim;
			this.location = props.location;
			this.position = props.position;
			this.size = props.size;
			this.queen = props.queen;
			this.evenCellColor = [133, 89, 53];
			this.oddCellColor = [212, 175, 113];
			this.cellColor =
				(this.position.x + this.position.y) % 2 === 0
					? this.evenCellColor
					: this.oddCellColor;
		}

		/**
		 * draw this cell
		 * @param [color] - this cell's color
		 */
		draw(color = this.cellColor) {
			push();
			fill(color);
			rect(this.location.x, this.location.y, this.size.x, this.size.y);
			redraw();
			pop();
		}

		/**
		 * place a queen on this cell.
		 */
		setQueen() {
			this.draw([26, 29, 46]);
			push();
			const locX = this.location.x + this.size.x / 2;
			const locY = this.location.y + this.size.y / 2;
			const scale = 0.5;
			const sizeX = this.size.x * scale;
			const sizeY = this.size.y * scale;
			image(this.queen, locX, locY, sizeX, sizeY);
			pop();
			this.hasQueen = true;
		}

		/**
		 * remove a queen on this cell.
		 */
		unsetQueen() {
			this.draw();
			this.hasQueen = false;
		}


		equals(other){
			return this.key === other.key;
		}


		/**
		 * returns true if this cell's queen can attack the other cell's queen
		 * @param other - other queen cell
		 * @returns {boolean} boolean
		 */
		queenCanAttack(other) {
			if (this.equals(other)) return false;
			return (
				this.position.x === other.position.x ||
				this.position.y === other.position.y ||
				abs(this.position.x - other.position.x) ===
					abs(this.position.y - other.position.y)
			);
		}

		/**
		 * returns true if this cell's queen can attack any cell's queen in the others array.
		 * @param {Cell[]} others - array of other queen cells
		 * @returns {boolean} boolean
		 */
		queenCanAttackAny(others) {
			for (let other of others) {
				if (this.queenCanAttack(other)) return true;
			}
			return false;
		}
	}
</script>

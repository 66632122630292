import {get} from "svelte/store";
import {runStatus, iterationCount, solution} from "../stores/settings";
import {Cell} from "./Cell.svelte";

export default function Board(queen) {
	this.iterator = null;
	this.dim = null;
	this.board;
	this.run = () => {
		this.iterator && this.iterator.next();
	};

	/**
	 * reset the board
	 */
	this.reset = () => {
		this.iterator = null;
		this.dim = null;
	};

	/**
	 * redraw the board
	 * @param {number} dimension
	 */
	this.resetBoard = dimension => {
		if (this.dim === dimension) return;
		this.dim = dimension;
		runStatus.set(false);
		iterationCount.reset();
		solution.reset();
		/**
		 * @TODO reset ui elements here.
		 */
		this.board = new p5.Table();
		for (let x = 0; x < this.dim; x++) {
			this.board.addRow();
			this.board.addColumn(`${x}`);
		}

		for (let row = 0; row < this.dim; row++) {
			for (let col = 0; col < this.dim; col++) {
				const x = map(row, 0, this.dim, 0, width);
				const y = map(col, 0, this.dim, 0, height);
				const location = createVector(x, y);
				const key = row + col * this.dim;
				const position = createVector(row, col);
				const size = createVector(width / this.dim, height / this.dim);
				const cell = new Cell({
					key,
					dim: this.dim,
					location,
					position,
					size,
					queen,
				});
				this.board.set(row, col, cell);
				cell.draw();
				// cell.setQueen();
			}
		}

		this.getRowCount = () => this.board.getRowCount();
		this.getColCount = () => this.board.getColumnCount();
		this.getCell = (row, col) => this.board.get(row, col);
		this.setSolved = () => runStatus.set(true);
		this.increment = () => iterationCount.set();
		this.setSolution = (sol) => solution.set(sol);
	};
}

<script context="module">
	let parent;
	export let board;
	export let backtracking;
	let qs = window.$;
</script>

<script>
	import {dimension} from "../stores/settings";
	import Board from './Board';
	import Backtracking from "./algorithms/Backtracking";
	// let board;
	let queen;
	// let backtracking;

	window.keyPressed = () => {
		switch (keyCode) {
			case 72:
				qs('.qs_main').toggle()
				break;
			case 77:
			qs('#controls-body').toggle()
			break;
			case 83:
				board.reset();
				backtracking.run();
				break;
			case 67:
				board.reset();
				break;
			default:
				break;
		}
	}
	window.preload = function preload(){
		queen = loadImage('/queen.svg');
		board = new Board(queen);
		backtracking = new Backtracking(board);
	}
	window.setup = function setup() {
		createCanvas(windowWidth, windowHeight).parent(parent);
		frameRate(10000)
		imageMode(CENTER);
		board.resetBoard($dimension);
	};

	window.draw = function () {
		// reset board when dimension changes
		board.resetBoard($dimension);
// console.log(frameRate());
		// listen for command to run an algorithm
		board.run();

	};

	window.windowResized = function windowResized() {
		board.reset()
		resizeCanvas(windowWidth, windowHeight);

	};
</script>

<div id="sketch" class="w-full h-full" bind:this={parent} />
